import React, { useState } from "react"
import styled from "styled-components"

const Card = styled.div`
  background-color: #f6f9fb;
  box-shadow: 1px 3px 4px rgba(87, 87, 87, 0.25);
  border-radius: 16px;
  margin-bottom: 5%;
  display: flex;
  flex-direction: row;
  padding: 2% 3%;
  width: 100%;
  @media (max-width: 700px) {
    flex-direction: column;
  }
`
const DivInfo = styled.div`
  width: 70%;
  padding-right: 7%;
  font-family: Archivo;
  font-style: normal;
  .place {
    color: #000000;
    opacity: 0.7;
  }
  @media (max-width: 1199px) {
    padding-right: 4%;
  }
  @media (max-width: 700px) {
    width: 100%;
    padding: 0%;
    .place {
      font-size: 12px;
    }
  }
`
const TextDate = styled.p`
  text-transform: uppercase;
  color: #22959c;
  font-size: 16px;
  @media (max-width: 700px) {
    font-size: 12px;
  }
`
const TextName = styled.p`
  text-transform: uppercase;
  font-weight: 500;
  font-size: 20px;
  @media (max-width: 700px) {
    font-size: 13px;
    font-weight: bold;
  }
`
const TextDescription = styled.p`
  color: #767b8a;
  font-size: 16px;
  a {
    cursor: pointer;
    color: red !important;
    text-decoration: underline red !important;
  }

  @media (max-width: 700px) {
    font-size: 12px;
  }
`
const DivImg = styled.div`
  width: 30%;
  @media (max-width: 1199px) {
    padding-top: 3%;
  }
  @media (max-width: 960px) {
    padding-top: 10%;
  }
  @media (max-width: 700px) {
    width: 100%;
    padding-top: 0%;
  }
`
const Img = styled.img`
  width: 90%;
  @media (max-width: 700px) {
    width: 50%;
    padding: 0%;
  }
  @media (max-width: 500px) {
    width: 70%;
  }
`

function NewsCard(props) {
  const [show, setShow] = useState(false)
  return (
    <Card>
      <DivInfo>
        <TextDate>{props.date}</TextDate>
        <TextName>{props.name}</TextName>
        <p className="place">{props.place}</p>
        <TextDescription>
          {props.description}
          {show && props.restDescription}
          {props.restDescription && (
            <span>
              <a onClick={() => setShow(prev => !prev)}>
                {show ? "Leer menos" : "Leer más"}
              </a>
            </span>
          )}
          {props.link && (
            <span>
              <a href={props.link} target="_blank">
                Leer más
              </a>
            </span>
          )}
        </TextDescription>
      </DivInfo>
      <DivImg>
        <Img src={props.image} />
      </DivImg>
    </Card>
  )
}

export default NewsCard
