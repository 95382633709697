import "bootstrap/dist/css/bootstrap.min.css"
import styled from "styled-components"
import React, { useEffect, useState, useRef } from "react"
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
@keyframes fade-up {
  
  0%{
    opacity: 0;
    transform: translateY(250px) scale(0.9);
  }
  
  100%{
    opacity: 1;
    transform: translateY(0px) scale(1);
  }
  
  }
  
`;

const Text = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  /*   font-family: "Archivo", sans-serif; */
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  justify-content: center;
  letter-spacing: 0.055em;

  @media (max-width: 700px) {
    width: 100%;
  }
`

const HeaderStyled = styled.h1`
  position: relative;
  width: 100%;
  font-style: normal;
  font-weight: 600;
  font-size: 2.5vw;
  letter-spacing: 0.055em;
  color: #303030;

  @media (max-width: 700px) {
    font-size: 16px;
    width: 80%;
  }
`

const SectionTitle = styled.div`
  display: inline-block !important;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 150.3%;
  width: 100%;
  text-align: left;
  letter-spacing: 0.055em;
  text-transform: uppercase;
  color: #262626;

  &.animate {
    animation: fade-up 1s;
  }

  @media (max-width: 700px) {
    font-size: 16px;
    width: 80%;
  }
`

const Content = styled.div`
  display: flex;
  //padding: 3% 9% 0px 9%;
  padding: 2% 9% 3% 9%;
  margin: 0px;
  flex-wrap: wrap;
`

const SectionParagraph = styled.p`
  margin-top: 2rem;
  margin-bottom: 0rem;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 136.8%;
  letter-spacing: 0.1em;
  color: #767b8a;
  //color:pink;
  padding-left: 12px;
  padding-right: 25px;
  font-size: ${props => props.fontSize || "16px"};


  &.animate {
    animation: fade-up 1s;
  }

  @media (min-width: 700px) {
    font-size: 16px;
  }
  @media (min-width: 1050px) {
    font-size: 18px;
  }
  @media (max-width: 900px) {
    letter-spacing: 0;
    margin-top: 1.5rem;
  }
`



function Intro(props) {

  const [show, setShow] = useState(false);
  
  useEffect(() => {
    window.addEventListener("scroll",()=>{
      console.log("window.scrollY",window.scrollY)
      console.log("props.children.length",props.children.length)
     
      if(window.screen.width > 600){
      if(window.scrollY > 280) {
        console.log("props.children",props.children)
        setShow(true);
      } else {
        setShow(false);
      }}else{
        setShow(true);
      }
    })
  }, [])


  return (
    <>
      <Content
      >
        <Text>

        {show&&  <SectionTitle className={show ? "animate" : ""}>{props.title}</SectionTitle>}
        {show&& <SectionParagraph fontSize={props.fontSize} className={show ? "animate" : ""}>{props.children}</SectionParagraph>}

  
        </Text>
      </Content>
    </>
  )
}

export default Intro
