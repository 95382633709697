import React from "react"

//componentes
import MainLayout from "../components/Layouts/MainLayout"
import Picture from "../components/Picture"
import Intro from "../components/Intro"
import NewsContainer from "../components/NewsContainer"
import Page404 from "./404.js"

// data
const dataNews = [
  {
    id: 1,
    date: "PRÓXIMAMENTE",
    name: "INTRODUCCIÓN AL DATA SCIENCE",
    place: "",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dictumst molestie gravida maecenas quam turpis lorem. In gravida risus ac eu libero dolor et. Mi nibh ornare eros id. Blandit quisque pulvinar tempus tempor magna tristique faucibus felis, leo. Viverra quis porttitor scelerisque non. ",
    restDescription:
      "Blandit quisque pulvinar tempus tempor magna tristique faucibus felis, leo. Viverra quis porttitor scelerisque non.",
    image: "/newImg.jpg",
  },
  {
    id: 2,
    date: "PRÓXIMAMENTE",
    name: "TRANSFORMACIÓN DIGITAL",
    place: "",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dictumst molestie gravida maecenas quam turpis lorem. In gravida risus ac eu libero dolor et. Mi nibh ornare eros id. Blandit quisque pulvinar tempus tempor magna tristique faucibus felis, leo. Viverra quis porttitor scelerisque non. Nec suspendisse tincidunt. ",
    restDescription:
      "Blandit quisque pulvinar tempus tempor magna tristique faucibus felis, leo. Viverra quis porttitor scelerisque non.",
    image: "/newImg.jpg",
  },
  {
    id: 3,
    date: "PRÓXIMAMENTE",
    name: "GESTIÓN DE PROYECTOS INNOVADORES",
    place: "",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dictumst molestie gravida maecenas quam turpis lorem. In gravida risus ac eu libero dolor et. Mi nibh ornare eros id. Blandit quisque pulvinar tempus tempor magna tristique faucibus felis, leo. Viverra quis porttitor scelerisque non. Nec suspendisse tincidunt. ",
    restDescription:
      "Blandit quisque pulvinar tempus tempor magna tristique faucibus felis, leo. Viverra quis porttitor scelerisque non.",
    image: "/newImg.jpg",
  },
  {
    id: 4,
    date: "PRÓXIMAMENTE",
    name: "INTRODUCCIÓN AL DATA SCIENCE",
    place: "",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dictumst molestie gravida maecenas quam turpis lorem. In gravida risus ac eu libero dolor et. Mi nibh ornare eros id. Blandit quisque pulvinar tempus tempor magna tristique faucibus felis, leo. Viverra quis porttitor scelerisque non. ",
    restDescription:
      "Blandit quisque pulvinar tempus tempor magna tristique faucibus felis, leo. Viverra quis porttitor scelerisque non.",
    image: "/newImg.jpg",
  },
  {
    id: 5,
    date: "PRÓXIMAMENTE",
    name: "TRANSFORMACIÓN DIGITAL",
    place: "",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dictumst molestie gravida maecenas quam turpis lorem. In gravida risus ac eu libero dolor et. Mi nibh ornare eros id. Blandit quisque pulvinar tempus tempor magna tristique faucibus felis, leo. Viverra quis porttitor scelerisque non. Nec suspendisse tincidunt. ",
    restDescription:
      "Blandit quisque pulvinar tempus tempor magna tristique faucibus felis, leo. Viverra quis porttitor scelerisque non.",
    image: "/newImg.jpg",
  },
]

export default function Courses() {
  return (
    <>
<Page404/>
</>

/*
    <MainLayout active={3}>
      <Picture image="/courses.png"></Picture>
      <Intro title="EDUCACIÓN CONTINUA">
        <br></br>
        Acompañamos a organizaciones en su proceso de madurez hacia una cultura
        data driven. Trabajamos en el diagnóstico de madurez, diseño y ejecución
        de programas de formación en data & analytics para todos los niveles de
        la organización.
        <br></br>
        <br></br>
        <br></br>
        <br></br>
      </Intro>
       <NewsContainer title="CURSOS IN-HOUSE" isNews={false} data={dataNews} />
  </MainLayout>*/

  
  )
}
