import React, { useState } from "react"
import NewsCard from "./NewsCard"
import styled from "styled-components"
import { Row, Col } from "reactstrap"
import { Pagination, PaginationItem, PaginationLink } from "reactstrap"

//react icons
import { AiOutlineSearch } from "react-icons/ai"
import { IconContext } from "react-icons"

const ContainerDiv = styled.div`
  margin: 50px 4%;
`
const ContainerHeader = styled(Row)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 30px 20px 40px;
  margin: 0px;
  @media (max-width: 500px) {
    flex-direction: column;
    padding: 20px 30px 0px 0px;
  }
`
const Title = styled.h2`
  padding: 1% 9% 0% 1%;
  margin: 0px;
  font-family: "Archivo", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  letter-spacing: 0.055em;
  @media (max-width: 1100px) {
    font-size: 28px;
  }
  @media (max-width: 700px) {
    font-size: 22px;
  }
  @media (max-width: 500px) {
    font-size: 20px;
  }
`
const SearchCont = styled.div`
  display: flex;
  width: 20%;
  height: 50px;
  margin-right: 15px;
  flex-direction: row;
  border-radius: 4px;
  box-sizing: border-box;
  border: 1px solid #c6c6c6;
  background-color: #f6f9fb;
  .social_icon {
    margin-left: 0.7rem;
    margin-top: 0.6rem;
  }
  @media (max-width: 1100px) {
    width: 25%;
  }
  @media (max-width: 700px) {
    width: 30%;
    height: 40px;
    .social_icon {
      margin-left: 0.5rem;
      margin-top: 0.3rem;
    }
  }
  @media (max-width: 500px) {
    width: 60%;
    margin-top: 4%;
    margin-left: 5%;
  }
`
const InputSearch = styled.input`
  width: 100%;
  border: none;
  padding: 0px;
  font-size: 20px;
  background-color: #f6f9fb;
  :hover,
  :focus,
  :active {
    outline: none;
  }
  @media (max-width: 1100px) {
    font-size: 18px;
  }
  @media (max-width: 700px) {
    font-size: 16px;
  }
  @media (max-width: 500px) {
    font-size: 15px;
  }
`
const ContainerCards = styled.div`
  padding: 20px 30px 20px 70px;
  @media (max-width: 700px) {
    padding: 20px 30px;
  }
`
const ContainerPagination = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-end;
  .info-pag {
    font-size: 16px;
    font-family: Archivo;
    font-style: normal;
    margin-right: 2%;
    padding-top: 0.5%;
    align-items: center;
  }
  .icon {
    background-color: #ec5959;
    border-radius: 4px;
    color: white;
    position: relative;
    display: block;
    padding: 0% 0.5rem;
    margin-left: 20px;
    cursor: pointer;
    span {
      font-size: 22px;
      font-weight: 500;
    }
  }
`

function NewsContainer(props) {
  const limit = 4
  const [data, setData] = useState(props.data)
  const totalItems = data.length
  const [firstNumber, setFirstNumber] = useState(1)
  let newData
  totalItems <= limit ? (newData = totalItems) : (newData = limit)
  const [lastNumber, setLastNumber] = useState(newData)

  function handleChange(e) {
    const dataNews = props.data
    const newData = dataNews.filter(elem =>
      elem.name.toUpperCase().includes(e.target.value.toUpperCase())
    )
    newData.length <= limit
      ? setLastNumber(newData.length)
      : setLastNumber(limit)
    setData(newData)
  }

  function nextPage() {
    if (lastNumber < totalItems) {
      const number1 = firstNumber + limit
      const number2 = lastNumber + limit
      setFirstNumber(number1)
      number2 > totalItems ? setLastNumber(totalItems) : setLastNumber(number2)
    }
  }
  function previousPage() {
    if (firstNumber !== 1) {
      const number1 = firstNumber - limit
      setFirstNumber(number1)
      const number2 = lastNumber - limit
      number2 < totalItems
        ? setLastNumber(number1 + limit - 1)
        : setLastNumber(number2)
    }
  }
  const currentData = data.slice(firstNumber - 1, lastNumber)

  return (
    <ContainerDiv>
      <ContainerHeader>
        <Col>
          <Title>{props.title}</Title>
        </Col>
        {props.isNews && (
          <SearchCont>
            <IconContext.Provider
              value={{
                size: "30px",
                position: "absolute",
                className: "social_icon",
              }}
            >
              <AiOutlineSearch></AiOutlineSearch>
            </IconContext.Provider>
            <InputSearch
              type="text"
              placeholder="Buscar"
              onChange={e => handleChange(e)}
            />
          </SearchCont>
        )}
      </ContainerHeader>
      <ContainerCards>
        {currentData.map(elem => {
          return <NewsCard {...elem} />
        })}
        <ContainerPagination>
          <div className="info-pag">
            {firstNumber}-{lastNumber} of {totalItems} items
          </div>
          <Pagination
            className="btn-pag"
            size="sm"
            aria-label="Page navigation example"
          >
            <PaginationItem>
              <btn className="icon" previous onClick={() => previousPage()}>
                <span>{"<"}</span>
              </btn>
            </PaginationItem>
            <PaginationItem>
              <btn className="icon" next onClick={() => nextPage()}>
                <span>{">"}</span>
              </btn>
            </PaginationItem>
          </Pagination>
        </ContainerPagination>
      </ContainerCards>
    </ContainerDiv>
  )
}

export default NewsContainer
